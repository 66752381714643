import { Vector3 } from 'three'
import { Disk } from './disk'
import { ShapeCurve } from './mesh-maker'
import { Sphere } from './sphere'
import { Spline } from './spline-classes'
import * as AedittoMath from './aeditto-math'

export function pickTextColorBasedOnBgColor(
  bgColor: string,
  lightColor: string = 'white',
  darkColor: string = 'black'
) {
  if (lightColor == null) lightColor = 'white'
  if (darkColor == null) darkColor = 'black'
  if (bgColor == null) return lightColor
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16)
  var g = parseInt(color.substring(2, 4), 16)
  var b = parseInt(color.substring(4, 6), 16)
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
}

/*
Cyclic values cycle between start and end

if the range is 0-1
0.1 will be 0.1
1.1 will also be 0.1
-0.1 will be 0.9
*/
export class CyclicNormalizedValue {
  value: number
  range: { start: number; end: number }

  constructor(value: number, range = { start: 0, end: 1 }) {
    if (range.start > range.end)
      throw 'Please enter values for range where start is less than the end'

    this.range = range
    this.value = this.getRangedValue(value)
  }

  //adjusts the value to fall within the range.
  getRangedValue(value: number) {
    return (
      this.range.start +
      AedittoMath.mod(
        value - this.range.start,
        this.range.end - this.range.start
      )
    )
  }

  //gets the negative value
  getBelowRangeValue() {
    return this.value - this.range.end + this.range.start
  }

  // just check if the value is between start and end range
  isValueBetween(start: number, end: number) {
    const startU = new CyclicNormalizedValue(start, this.range)
    const endU = new CyclicNormalizedValue(end, this.range)

    if (startU.value > endU.value) {
      const condition1 =
        this.value >= this.range.start && this.value <= endU.value
      const condition2 =
        this.value >= startU.value && this.value <= this.range.end

      return condition1 || condition2
    } else {
      return this.value >= startU.value && this.value <= endU.value
    }
  }

  //distance between this value and another one
  getCyclicDistanceFrom(uValue: number) {
    const startU = new CyclicNormalizedValue(uValue, this.range)
    if (this.value - startU.value >= 0) {
      return this.value - startU.value
    } else {
      const length1 = this.value - this.range.start
      const length2 = this.range.end - startU.value

      return length1 + length2
    }
  }

  //distance between 2 points in the range
  static getCyclicNormalizedDistanceBetween(
    start: number,
    end: number,
    range = { start: 0, end: 1 }
  ) {
    const startU = new CyclicNormalizedValue(start, range)
    const endU = new CyclicNormalizedValue(end, range)

    if (startU.value - endU.value >= 0) {
      const length1 = endU.value - range.start
      const length2 = range.end - startU.value

      return length1 + length2
    } else {
      return endU.value - startU.value
    }
  }
}

export class SortedArray<T> {
  array: T[]
  propertyName: string
  constructor(propertyName: string, array: T[]) {
    this.array = []
    this.propertyName = propertyName

    if (array && array.length > 0)
      this.array = array.sort(
        (a: any, b: any) => a[this.propertyName] - b[this.propertyName]
      )
    else this.array = []
  }

  getInsertionIndex(value: T) {
    var low = 0,
      high = this.array.length

    while (low < high) {
      var mid = (low + high) >>> 1
      if (
        (this.array[mid] as any)[this.propertyName] <
        (value as any)[this.propertyName]
      )
        low = mid + 1
      else high = mid
    }
    return low
  }

  insert(value: T) {
    const insertionIndex = this.getInsertionIndex(value)
    this.array.splice(insertionIndex, 0, value)
  }
}

export class Coordinate {
  static convertViewerCoordinatesToPhysical(point: Vector3) {
    const convertedPoint = point.clone()
    convertedPoint.multiplyScalar(10)
    convertedPoint.applyAxisAngle(new Vector3(1, 0, 0), (-1 * Math.PI) / 2)

    return convertedPoint
  }

  static convertPhysialCoordinatesToViewer(point: Vector3) {
    const convertedPoint = point.clone()
    convertedPoint.multiplyScalar(0.1)
    convertedPoint.applyAxisAngle(new Vector3(1, 0, 0), Math.PI / 2)

    return convertedPoint
  }

  static getUValueFromPoint(point: Vector3, center = { x: 0, y: 0 }) {
    const centerVector = new Vector3(center.x, center.y, 0)
    const vector1 = new Vector3(point.x, point.y, 0).sub(centerVector)
    const unitVectorY = new Vector3(0, 1, 0).sub(centerVector)

    let u = vector1.angleTo(unitVectorY)

    const crossProduct = unitVectorY.clone().cross(vector1)
    if (crossProduct.z < 0) u = 2 * Math.PI - u

    u = u / (2 * Math.PI)

    return u
  }

  static getPointFromUvalue(
    u: number,
    radius: number,
    center = { x: 0, y: 0 }
  ) {
    const x = radius * Math.cos(u * 2 * Math.PI + Math.PI / 2)
    const y = radius * Math.sin(u * 2 * Math.PI + Math.PI / 2)
    const pointVector = new Vector3(x, y, 0)

    const vector1 = new Vector3(center.x, center.y, 0)
    const unitVectorY = new Vector3(0, -1, 0)

    let angle = vector1.angleTo(unitVectorY)

    const crossProduct = unitVectorY.clone().cross(vector1)
    if (crossProduct.z < 0) angle = 2 * Math.PI - angle

    if (Math.hypot(center.x, center.y) > 1e-14)
      pointVector.applyAxisAngle(new Vector3(0, 0, 1), angle)
    pointVector.add(new Vector3(center.x, center.y, 0))

    return pointVector
  }
}

export function serializeState(
  spline: Spline,
  color: string,
  profiles: {
    startProfile: ShapeCurve
    endProfile: ShapeCurve
    intermediateShapes: ShapeCurve[]
  },
  socketSize: number,
  spheres: Array<Sphere | Disk>,
  surfaceModifier: any,
  additionalOptions: any
) {
  return JSON.stringify({
    spline: Spline.serialize(spline),
    color: color,
    socketType: spline.modelType,
    profiles: JSON.stringify({
      startProfile: ShapeCurve.serialize(profiles.startProfile),
      endProfile: ShapeCurve.serialize(profiles.endProfile),
      intermediateShapes: profiles.intermediateShapes.map(ShapeCurve.serialize),
    }),
    socketSize: socketSize,
    spheres: JSON.stringify(spheres.map((sphere) => Sphere.serialize(sphere))),
    surfaceModifier: JSON.stringify(surfaceModifier),
    additionalOptions: JSON.stringify(additionalOptions),
  })
}


export function nil(x: any) {
  if (typeof x === 'undefined') {
    return true
  }
  if (x === null) {
    return true
  }
  return false
}
