import { Vector3 } from 'three'
import { NextApiRequest, NextApiResponse } from 'next'

export const schemaVersion = '2.2'

export class PrinterType {
  id: string
  displayName: string
  dimensions: Vector3
  additionParameters: object

  constructor(
    id: string,
    dimensions: Vector3,
    additionParameters: object = {}
  ) {
    this.id = id
    //displayName is the same as id but they are different fields in case we ever need to display one
    this.displayName = id
    this.dimensions = dimensions
    this.additionParameters = additionParameters
  }
}

export class EditorType {
  id: string
  displayName: string
  printerType: PrinterType

  constructor(id: string, printerType: PrinterType) {
    this.id = id
    //displayName is the same as id but they are different fields in case we ever need to display one
    this.displayName = id
    this.printerType = printerType
  }

  getSmallerXYDimension() {
    const printerDimensions = this.printerType.dimensions
    return Math.min(printerDimensions.x, printerDimensions.y)
  }
}

export enum modelTypeFlags {
  isSplineBased,
  isIneBased,
  isSketchBased,
  shouldShowAdvancedEditor,
  shouldTaperModifier,
  shouldHaveSplineScaler,
  shouldHaveTaperScaler,
  showWidthInSplineScaler,
  showHeightInSplineScaler,
}

export class ModelType {
  id: string
  displayName: string
  editorType: EditorType
  flags: modelTypeFlags[]
  updationTimeout: number
  minHeight: number
  socketSize: number
  flankSize: number
  cameraPosition: { position: Vector3; zoom: number }
  modelOrientationAngle: number

  constructor(
    id: string,
    displayName: string,
    editorType: EditorType,
    flags = [modelTypeFlags.isSplineBased],
    updationTimeout = 3500,
    minHeight = 0,
    socketSize = 5,
    flankSize = 0,
    cameraPosition = { position: new Vector3(0, 0, 30), zoom: 20 },
    modelOrientationAngle = 0
  ) {
    this.id = id
    this.displayName = displayName
    this.editorType = editorType
    this.flags = flags
    this.updationTimeout = updationTimeout
    this.minHeight = minHeight
    this.socketSize = socketSize
    this.flankSize = flankSize
    this.cameraPosition = cameraPosition
    this.modelOrientationAngle = modelOrientationAngle
  }
}

export const listOfPickerColors = [
  '74B1FB',
  '1BC000',
  'FFCBF1',
  'F6EADE',
  '807E76',
]

//URL for main website
export const websiteURL = 'https://lyla-design.com'

//key for the object should always be the same as the id of the object
//TODO Find a better way to enforce above^ condition
const printerTypes = {
  artilerryX2: new PrinterType('artilerryX2', new Vector3(250, 250, 250)),
  clayPrinter: new PrinterType('clayPrinter', new Vector3(250, 250, 250)),
  gingerV1: new PrinterType('gingerV1', new Vector3(1000, 1000, 1000)),
}

//key for the object should always be the same as the id of the object
export const editorTypes = {
  lamp: new EditorType('lamp', printerTypes.artilerryX2),
  smallVase: new EditorType('smallVase', printerTypes.clayPrinter),
  pablo: new EditorType('pablo', printerTypes.gingerV1),
  boucle: new EditorType('boucle', printerTypes.gingerV1),
  sketch: new EditorType('sketch', printerTypes.gingerV1),
  bubble: new EditorType('bubble', printerTypes.gingerV1),
  candle: new EditorType('candle', printerTypes.gingerV1),
  bigLamp: new EditorType('bigLamp', printerTypes.gingerV1),
  stacker: new EditorType('stacker', printerTypes.gingerV1),
  waterDrop: new EditorType('waterDrop', printerTypes.gingerV1),
}

//key for the object should always be the same as the id of the object
export const modelTypes = {
  leroyMerlin: new ModelType(
    'leroyMerlin',
    'Table Lamp',
    editorTypes.lamp,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    100,
    20.7 * 2,
    0,
    { position: new Vector3(0, 15, 25), zoom: 20 },
    Math.PI
  ),

  smallVase: new ModelType(
    'smallVase',
    'Small Vase',
    editorTypes.smallVase,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    50,
    0,
    30,
    { position: new Vector3(0, 15, 25), zoom: 20 },
    Math.PI
  ),

  e27: new ModelType(
    'e27',
    'Pending Lamp Shade',
    editorTypes.lamp,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    100,
    46,
    20.5,
    { position: new Vector3(0, 0, 30), zoom: 20 },
    0
  ),

  pablo: new ModelType(
    'pablo',
    'Pablo Stool',
    editorTypes.pablo,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    12000,
    100,
    5,
    0,
    { position: new Vector3(0, 0, 110), zoom: 7 },
    0
  ),

  boucle: new ModelType(
    'boucle',
    'Boucle Chair',
    editorTypes.boucle,
    [
      modelTypeFlags.isIneBased,
      modelTypeFlags.shouldHaveSplineScaler,
      modelTypeFlags.showHeightInSplineScaler,
    ],
    12000,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 110), zoom: 20 },
    0
  ),

  sketch: new ModelType(
    'sketch',
    'Sketch',
    editorTypes.sketch,
    [
      modelTypeFlags.isSketchBased,
      modelTypeFlags.shouldHaveSplineScaler,
      modelTypeFlags.showHeightInSplineScaler,
    ],
    12000,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 200), zoom: 5 },
    0
  ),

  bubble: new ModelType(
    'bubble',
    'Bubbles by Lucas Zito',
    editorTypes.bubble,
    [
      modelTypeFlags.shouldHaveSplineScaler,
      modelTypeFlags.showWidthInSplineScaler,
      modelTypeFlags.showHeightInSplineScaler,
    ],
    3500,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 90), zoom: 8 },
    0
  ),

  candle: new ModelType(
    'candle',
    'Candle Holder by Teti',
    editorTypes.candle,
    [
      modelTypeFlags.shouldHaveSplineScaler,
      modelTypeFlags.showWidthInSplineScaler,
      modelTypeFlags.showHeightInSplineScaler,
    ],
    12000,
    100,
    0,
    0,
    { position: new Vector3(0, 0, 90), zoom: 8 },
    0
  ),

  bigLamp: new ModelType(
    'bigLamp',
    'Pending Lamp Shade XL',
    editorTypes.bigLamp,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    100,
    46,
    20.5,
    { position: new Vector3(0, 0, 90), zoom: 8 },
    0
  ),

  vase: new ModelType(
    'vase',
    'Vase',
    editorTypes.bigLamp,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    50,
    2,
    120,
    { position: new Vector3(0, 90, 90), zoom: 10 },
    Math.PI
  ),

  waterDrop: new ModelType(
    'waterDrop',
    'water drop',
    editorTypes.waterDrop,
    [],
    3500,
    100,
    0,
    0,
    { position: new Vector3(0, 15, 25), zoom: 20 },
    Math.PI
  ),

  stacker: new ModelType(
    'stacker',
    'Stackable',
    editorTypes.stacker,
    [
      modelTypeFlags.isSplineBased,
      modelTypeFlags.shouldShowAdvancedEditor,
      modelTypeFlags.shouldTaperModifier,
    ],
    3500,
    100,
    46,
    20.5,
    { position: new Vector3(0, 0, 90), zoom: 8 },
    0
  ),
}

export const constants = {
  dotDiameter: 13,
  editorWidth: 300,
  colors: {
    semiTransparentWhite: 'RGBA(255, 255, 255, 0.24)',
    semiTransparentBlack: 'RGBA(0, 0, 0, 0.24)',
  },
  shapeNames: {
    circle: 'circle',
    polygon: 'polygon',
    custom: 'custom',
    oval: 'oval',
  },
  minimumLampHeight: { e27: 100, bigLamp: 100, vase: 50, leroyMerlin: 100 },
  surfaceModifierNames: {
    none: 'none',
    sinusoid: 'Wavy',
    sinusoidAlt: 'Woven',
  },
}

export const discountPercentageConfig = 50

export function getUrlFromRequest(req: NextApiRequest) {
  let host = req.headers.host!
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'

  // If server sits behind reverse proxy/load balancer, get the "actual" host ...
  if (
    req.headers['x-forwarded-host'] &&
    typeof req.headers['x-forwarded-host'] === 'string'
  ) {
    host = req.headers['x-forwarded-host']
  }

  // ... and protocol:
  if (
    req.headers['x-forwarded-proto'] &&
    typeof req.headers['x-forwarded-proto'] === 'string'
  ) {
    protocol = `${req.headers['x-forwarded-proto']}:`
  }

  return protocol + '//' + host
}

export const waterdrop_max_brushSize = 330
export const waterdrop_min_brushSize = 55
export const waterdrop_initial_baseGridCellSize = 5
export const waterdrop_initial_gridSize = { x: 660, y: 660, z: 660 }

export const listOfInspirationUUIDs = [
  '7a665f0b-4673-4ce6-b497-8d5988c93b39',
  'da18c66d-cfa4-4c1a-880b-8a328f433f47',
  '8b6429ef-48fa-4925-8544-cc0cddcd260a',
  'd2954083-8dd0-4012-af29-a93032301fc9',
  '63932197-ddc2-4e87-b063-556939da5d3e',
  '760fbc91-e934-4371-b013-236da7db9454',
  'bec63952-9c3f-488e-8682-c3f5338d39f2',
  '333118b0-af8a-4602-aefe-f477069a9bb7',
  'cf6aa16c-b56d-4364-9883-1816543efd75',
  '1b7529a6-e782-49eb-a302-a54fb34f927d',
  'b8b636e1-149a-4175-8c5a-7a9041b8fbdf',
  '6b8cf99f-8b44-45de-ae7f-d3dcfdb31a77',
  '16988842-d7cd-45df-8f7f-afdf2a9b8645',
]
