import { distance, min, sub, cross, normalize } from './utils2'
import { normal as _normal } from './utils2'
export function circle(radius, center) {
  return (t) => [
    radius * Math.sin(t * 2 * Math.PI) + center.x,
    radius * Math.cos(t * 2 * Math.PI) + center.y,
    center.z,
  ]
}

export function* generate_nearby_ts(c, opts, include_last = true) {
  let d_max = opts.path.max_ddistance * 0.9
  for (
    var t_step = 0;
    t_step < 1 - opts.curve_step / 2;
    t_step += opts.curve_step
  ) {
    let p1 = c(t_step)
    let p2 = c(min([t_step + opts.curve_step, 1]))
    let d = distance(p1, p2)
    if (d <= d_max) {
      yield t_step
    } else {
      let d_min = opts.path.min_ddistance
      let n = Math.ceil(d / d_min) * opts.oversampling
      let t_fine = opts.curve_step / n
      let t_stop = t_step + opts.curve_step - t_fine / 2
      for (var t = t_step; t < t_stop; t += t_fine) {
        yield t
      }
    }
  }
  if (include_last) {
    yield 1
  }
}

export function* generate_radii(r1, r2, dt) {
  if (r1 > r2) {
    for (var r = r1; r > r2; r -= dt) {
      yield r
    }
  } else {
    for (var r = r1; r < r2; r += dt) {
      yield r
    }
  }
}

export function normal(s, t, u, resolution = 0.002) {
  // this handles edge cases, when we hit the bordes of our parameter space
  if (t + resolution <= 1.0 && u + resolution <= 1.0) {
    let p0 = s(t, u)
    let p1 = s(t, u + resolution)
    let p2 = s(t + resolution, u)
    return _normal(p0, p1, p2)
  } else if (t + resolution > 1.0 && u - resolution < 0.0) {
    let p0 = s(t, u)
    let p1 = s(t - resolution, u)
    let p2 = s(t, u + resolution)
    return _normal(p0, p1, p2)
  } else if (t - resolution < 0.0 && u + resolution > 1.0) {
    let p0 = s(t, u)
    let p1 = s(t + resolution, u)
    let p2 = s(t, u - resolution)
    return _normal(p0, p1, p2)
  } else {
    let p0 = s(t, u)
    let p1 = s(t, u - resolution)
    let p2 = s(t - resolution, u)
    return _normal(p0, p1, p2)
  }
}

export function normal_xy(s, t, u, resolution = 0.002) {
  // this handles edge cases, when we hit the bordes of our parameter space
  let [x, y, z] = normal(s, t, u)
  let vec = [x, y, 0]
  return normalize(vec)
}
