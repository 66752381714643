// functionality to generate gcode from a parametric surface s(u,v) in vase mode (continously increasing z coordinate)
import { pairwise, downsample, eduction, max, enumerate } from './itertools'
import { check_pairwise, check_p_pn } from './checks'
import { assoc } from './immutant'
import {
  generate_socket_brim as filled_socket,
  generate_brim,
  skirt,
  just_shape,
  gen_path,
  make_annotated2,
} from './brim'
import { partitionAll } from './partitionall'
import { generate_gcode_annotated } from './assembler'
import { normal } from './geometry'
import {
  clip,
  range,
  linear,
  linear2,
  distance,
  find_u_for_z,
  nil,
  is_true,
} from './utils2'
import {
  attach_deltas,
  add_volume,
  total_volume,
  discard_on_distance,
  apply_modifier,
  without_modifier,
  get_bbox,
} from './shared'
import { assocPath } from 'lodash/fp'

var t = require('transducers-js')

//generate layer heights for shell,

function* equidistant_heights(start_height, layer_height) {
  for (const i of range(0, 1)) {
    yield {
      height: {
        start: start_height + layer_height * i,
        end: start_height + layer_height * (i + 1),
      },
      layer_height: { start: layer_height, end: layer_height },
      tag: 'shell',
      group: 'object',
    }
  }
}

function smallest_ramp(start_height, min_layer_height) {
  return {
    height: {
      start: start_height + min_layer_height,
      end: start_height + min_layer_height * 2,
    },
    layer_height: { start: min_layer_height, end: min_layer_height * 2 },
    tag: 'ramp1',
    group: 'object',
  }
}

function ramp_to_layerheight(start_height, start_layerheight, layer_height) {
  return {
    height: {
      start: start_height,
      end: start_height + layer_height,
    },
    layer_height: {
      start: start_layerheight,
      end: layer_height,
    },
    tag: 'ramp2',
    group: 'object',
  }
}

function _normal_with_overhang(s, t, u, discret_height) {
  let [x, y, z] = normal(s, t, u)
  let xy = Math.sqrt(x * x + y * y)
  return Math.abs((discret_height * z) / xy)
}
function _gen_overhang(s, h, discret_height) {
  let u = find_u_for_z(s, h)
  let overhang = t.transduce(
    t.map((t) => _normal_with_overhang(s, t, u, discret_height)),
    max(0),
    range(0, 0.01, 1.0)
  )
  return { overhang: overhang, nominal_height: h }
}

function accumulate_overhang(layers) {
  let l0 = layers[0]
  var acc = 0
  var overhangs = []
  for (var l of layers) {
    acc += l.overhang
    overhangs.push(acc)
  }
  return assoc(l0, { overhangs: overhangs })
}

function _add_layer_number(l) {
  let [n, layer] = l
  return assoc(layer, { layernumber: n })
}

function next_layer_number(l, min_num, max_num, max_overhang) {
  let overhangs = l.overhangs
  var layer = min_num
  for (var i = min_num; i <= max_num; i++) {
    if (
      overhangs[i - 1] > max_overhang &&
      overhangs[i * 2 - 1] > max_overhang
    ) {
      break
    }
    layer = i
  }
  return assoc(l, { next_layer_number: layer })
}

function skip_to_next_layer_number(last, next, input) {
  let next_num = last.layernumber + last.next_layer_number
  return input.layernumber <= next_num
}

function _gen_heights(start_layerheight) {
  var layerheight = start_layerheight
  return function _gen_heights_(l) {
    let [l1, l2] = l
    let end_height = l2.nominal_height - l1.nominal_height
    let r = {
      layer_height: {
        start: layerheight,
        end: end_height,
      },
      height: {
        start: l1.nominal_height,
        end: l2.nominal_height,
      },
      tag: 'shell',
      group: 'object',
    }

    layerheight = end_height

    return r
  }
}

function* _adaptive_heights(
  s,
  start_height,
  min_height,
  max_heigt,
  nozzle_size,
  min_overlap
) {
  const MAX_OVERHANG_PER_LAYER = nozzle_size * (1 - min_overlap)
  const DISCRET_HEIGHT = 0.1
  const MIN_NUM = Math.round(min_height / DISCRET_HEIGHT)
  const MAX_NUM = Math.round(max_heigt / DISCRET_HEIGHT)
  let n_prewatch = Math.round((max_heigt * 2) / DISCRET_HEIGHT)
  let fine_heights = range(start_height, DISCRET_HEIGHT)
  let xf = t.comp(
    t.map((h) => _gen_overhang(s, h, DISCRET_HEIGHT)),
    partitionAll(n_prewatch, 1),
    t.map(accumulate_overhang),
    enumerate(),
    t.map(_add_layer_number),
    t.map((x) =>
      next_layer_number(x, MIN_NUM, MAX_NUM, MAX_OVERHANG_PER_LAYER)
    ),
    downsample(skip_to_next_layer_number),
    pairwise,
    t.map(_gen_heights(min_height))
  )
  yield* eduction(xf, fine_heights)
}
function* adaptive_heights(
  s,
  start_height,
  min_height,
  max_heigt,
  nozzle_size,
  min_overlap
) {
  let ramp1 = smallest_ramp(start_height, min_height)
  yield ramp1
  yield* _adaptive_heights(
    s,
    ramp1.height.end,
    ramp1.layer_height.end - ramp1.layer_height.start,
    max_heigt,
    nozzle_size,
    min_overlap
  )
}

function* constant_heights(
  start_height,
  min_layerheight,
  constant_layerheight
) {
  let ramp1 = smallest_ramp(start_height, min_layerheight)
  yield ramp1

  let ramp2 = ramp_to_layerheight(
    ramp1.height.end,
    ramp1.layer_height.end - ramp1.layer_height.start,
    constant_layerheight
  )
  yield ramp2
  yield* equidistant_heights(ramp2.height.end, constant_layerheight)
}

function* heights(s, opts) {
  if (is_true(opts.adaptive_heights)) {
    yield* adaptive_heights(
      s,
      opts.start_height,
      opts.min_layer_height,
      opts.max_layer_height,
      opts.nozzle_size,
      opts.min_overlap
    )
  } else {
    yield* constant_heights(
      opts.start_height,
      opts.min_layer_height,
      opts.max_layer_height
    )
  }
}

function generate_required_ts(include) {
  return function* (data) {
    if (!include.includes(0)) {
      yield assoc(data, { t_required: 0, required: false })
    }
    for (const t of include) {
      yield assoc(data, { t_required: t, required: true })
    }
    if (!include.includes(1)) {
      yield assoc(data, { t_required: 1, required: false })
    }
  }
}

function generate_rough_ts(s, opts) {
  return function* (data) {
    let [p1, p2] = data
    let t_start = p1.t_required
    let t_end = p2.t_required
    if (t_start > t_end) {
      // reaching next layer
      return
    }
    let dt_required = t_end - t_start
    if (dt_required < opts.curve_step) {
      yield assoc(p1, { t_rough: { start: t_start, end: t_end } })
    } else {
      for (
        var t = t_start;
        t < t_end - opts.curve_step / 2;
        t += opts.curve_step
      ) {
        let end = Math.min(t + opts.curve_step, t_end)
        yield assoc(p1, {
          t_rough: { start: t, end: end, required: false },
        })
      }
    }
  }
}

function generate_rough_us(s, opts) {
  return function (data) {
    let t1 = data.t_rough.start
    let t2 = data.t_rough.end
    const z1 = linear(data.height.start, data.height.end, t1)
    const z2 = linear(data.height.start, data.height.end, t2)
    const u1 = find_u_for_z(s, z1)
    const u2 = find_u_for_z(s, z2)
    return assoc(data, { u_rough: { start: u1, end: u2 } })
  }
}

function generate_fine_ts(s, opts) {
  return function* (data) {
    let t_start = data.t_rough.start
    let t_end = data.t_rough.end
    let d_min = opts.path.min_ddistance
    let p_start = s(t_start, data.u_rough.start)
    let p_end = s(t_end, data.u_rough.end)
    let d = distance(p_start, p_end)
    let n = Math.ceil(d / d_min) * opts.oversampling
    let t_fine = opts.curve_step / n
    let t_stop = t_end - t_fine / 2
    for (var t = t_start; t < t_stop; t += t_fine) {
      let u = linear2(
        data.u_rough.start,
        data.u_rough.end,
        data.t_rough.start,
        data.t_rough.end,
        t
      )
      const layer_height = linear(
        data.layer_height.start,
        data.layer_height.end,
        t
      )
      const p_fine = s(t, u)
      const [x, y, z] = p_fine
      yield assoc(data, {
        point: [x, y, z],
        t: t,
        u: u,
        layer_height: layer_height,
        layer_data: data.layer_height,
      })
    }
  }
}

function add_layer_meta_data(s, opts) {
  function _add_layer_meta_data(n_l) {
    let [number, layer] = n_l
    let h1_bottom = layer.height.start - layer.layer_height.start
    let h1_up = layer.height.start
    let h2_bottom = layer.height.end - layer.layer_height.end
    let h2_up = layer.height.end
    const u1_bottom = find_u_for_z(s, h1_bottom)
    const u1_up = find_u_for_z(s, h1_up)
    const u2_bottom = find_u_for_z(s, h2_bottom)
    const u2_up = find_u_for_z(s, h2_up)
    return assoc(layer, {
      meta: {
        number: number,
        start: { u_bottom: u1_bottom, u1_up: u1_up },
        end: { u_bottom: u2_bottom, u1_up: u2_up },
      },
    })
  }
  return _add_layer_meta_data
}

function add_volume_correction(s) {
  function _add_volume_correction(data) {
    let [x, y, z] = normal(s, data.t, data.u)
    // 1/f=cos a = va * vb/ |va||vb|, where va = n and, vb=[nx,ny,0]
    let f = Math.sqrt(x * x + y * y) / (x * x + y * y)
    let uncorrected_volume = data.volume
    let n = assoc(data, {
      volume_correction_factor: f,
      uncorrected_volume: uncorrected_volume,
      volume: uncorrected_volume * f,
    })
    return n
  }
  return _add_volume_correction
}

function generate_shell(s, total_print_volume, opts) {
  const [, , zmax] = s(0, 1)
  const xf = t.comp(
    enumerate(0),
    t.map(add_layer_meta_data(s, opts)),
    t.mapcat(generate_required_ts(opts.use_steps)),
    pairwise,
    t.mapcat(generate_rough_ts(s, opts)),
    t.map(generate_rough_us(s, opts)),
    t.mapcat(generate_fine_ts(s, opts)),
    t.map((x) => apply_modifier(x, s, opts)),
    downsample(
      discard_on_distance(opts.path.min_ddistance, opts.path.max_ddistance)
    ),
    pairwise,
    attach_deltas,
    t.map(add_volume(opts.nozzle_size, opts.extrusion_multiplier)),
    t.map(
      opts.is_adaptive_wallthickness ? add_volume_correction(s) : t.identity
    ),
    t.map(total_volume(total_print_volume)),
    t.takeWhile((x) => x.point[2] < zmax),
    check_pairwise(check_p_pn(opts))
  )

  let u = find_u_for_z(s, opts.start_height + opts.min_layer_height)

  return [
    {
      points: t.into([], xf, heights(s, opts)),
      start: s(0, u),
    },
  ]
}

function generate_path(s, opts) {
  // todo assert 2 * layer_height >= min_layer_height;
  let base_height = opts.brim.layer_height
  let base_opts = assocPath(
    ['extrusion_multiplier'],
    opts.brim.extrusion_multiplier,
    opts
  )
  let socket = filled_socket(s, base_height, base_opts)
  opts = assoc(opts, { start_height: socket.end_height })
  return {
    has_socket: false,
    skirt: skirt(s, base_height, base_opts).brim,
    brim: generate_brim(s, base_height, base_opts).brim,
    socket: socket.brim,
    shell: generate_shell(s, 0, opts),
  }
}

function check_options(opts) {
  if (opts.path.max_ddistance <= opts.path.min_ddistance) {
    throw 'minimum distance must be smaller than maximum distance'
  }
  if (opts.oversampling < 2.0) {
    throw 'oversamlpling must be at greater or equal than 2.0'
  }
  if (opts.path.max_ddistance <= 0) {
    throw 'max distance must be a positive non zero number'
  }
  if (nil(opts.use_steps)) {
    opts.use_steps = []
  }
  return opts
}

function gcode(s, opts) {
  let opts_checked = check_options(opts)
  let path = generate_path(s, opts_checked)
  let code = generate_gcode_annotated(path, opts_checked)
  return t.into(
    '',
    t.map((x) => x.code),
    code
  )
}

function first_discretize_height(opts) {
  if (opts.brim.socket === 'none') {
    return opts.brim.layer_height + opts.min_layer_height
  }
  return opts.brim.layers * opts.brim.layer_height + opts.min_layer_height
}

export function create_gcode(mesh, opts) {
  if (opts.printer.firmware.cead_mode){
    throw Error("cead printers are not supported, yet")
  }
  console.log(opts)
  opts.first_discretize_height = first_discretize_height(opts)
  const f = function (u, v) {
    return mesh.getPoint(u, v, true)
  }
  return gcode(f, opts)
}

function generate_layer(mesh, opts, n) {
  let layer = n + 1
  const f = function (u, v) {
    let point = mesh(u, n).point
    point.z = opts.layer_height
    return point
  }
  const e_fn = function (t) {
    let f = mesh(t, n).flowMultiplier
    return f
  }
  const visit_fn = function (t) {
    let f = mesh(t, n).visitIndex
    return f
  }

  let curve = gen_path(f, 0, without_modifier(opts))
  let shape_path = make_annotated2(
    [curve],
    opts.layer_height * layer,
    opts.layer_height,
    assocPath(['visit_fn'], visit_fn, assocPath(['e_fn'], e_fn, opts)),
    'brim'
  ).brim
  return shape_path
}

function box(bbox) {
  let x = bbox.x
  let y = bbox.y
  let z = bbox.z
  let xmin = x.min
  let ymin = y.min
  let xmax = x.max
  let ymax = y.max
  let zmin = 0
  let zmax = z.max
  return function (te, ue) {
    let t = clip(te, 0, 1)
    let u = clip(ue, 0, 1)
    let zp = linear(zmin, zmax, u)
    if (t <= 0.25) {
      let yp = ymin
      let xp = linear2(xmin, xmax, 0, 0.25, t)
      return [xp, yp, zp]
    } else if (t <= 0.5) {
      let xp = xmax
      let yp = linear2(ymin, ymax, 0.25, 0.5, t)

      return [xp, yp, zp]
    } else if (t <= 0.75) {
      let yp = ymax
      let xp = linear2(xmax, xmin, 0.5, 0.75, t)
      return [xp, yp, zp]
    } else {
      let xp = xmin
      let yp = linear2(ymax, ymin, 0.75, 1, t)
      return [xp, yp, zp]
    }
  }
}


function line(start, end) {
  let x1 = start.x
  let y1 = start.y
  let z1 = start.z
  let x2 = end.x
  let y2 = end.y
  let z2 = end.z
  return function (te, ue){
    let t = clip(te, 0, 1)
    let x = linear(x1, x2, t)
    let y = linear(y1, y2, t)
    let z = linear(z1, z2, t)
    return [x, y, z]
  }
}

export function create_layered_gcode(mesh, opts) {
  if ( opts.adaptive_heights && opts.printer.firmware.cead_mode){
    throw Error("cead and adaptive layer heights is not supported")
  }
  let base_height = opts.brim.layer_height
  let base_opts = assocPath(['extrusion_multiplier'], 1.0, opts)
  var shapes = []
  const bbox = get_bbox([shapes])
  let skirt_shape = opts.brim.starting_point ? just_shape(line(opts.brim.starting_point, mesh(0,0).point), base_height, base_opts) : skirt(box(bbox), base_height, base_opts)
  for (var i = 0; i < opts.layers; i++) {
    let shape_path = generate_layer(mesh, opts, i)
    shapes = shapes.concat(shape_path)
  }

  let code = generate_gcode_annotated(
    {
      shell: shapes,
      skirt: skirt_shape.brim,
      brim: [],
      socket: [],
    },
    opts
  )
  return t.into(
    '',
    t.map((x) => x.code),
    code
  )
}

export function bar(i){
  return i + 1
}