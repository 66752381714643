import {
  CustomCatmullRom,
  CustomShapeSpline,
  IneSpline,
  Spline,
} from './spline-classes'
import {
  Box3,
  BoxHelper,
  BufferGeometry,
  CatmullRomCurve3,
  Color,
  Curve,
  DoubleSide,
  EllipseCurve,
  ExtrudeGeometry,
  Float32BufferAttribute,
  LineCurve3,
  Matrix4,
  Mesh,
  MeshStandardMaterial,
  Scene,
  Shape,
  Vector2,
  Vector3,
} from 'three'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { smallerXYDimension } from './configs'
import {
  constants,
  EditorType,
  ModelType,
  modelTypeFlags,
  modelTypes,
  editorTypes,
  discountPercentageConfig,
} from './constants'
import { Sphere } from './sphere'
import { Disk } from './disk'
import { CyclicNormalizedValue, SortedArray } from './utils'
import { GeometryStateType, GeometryState } from './state'

class Polyline3D extends Curve<Vector3> {
  points: Vector3[]
  constructor(points: Vector3[]) {
    super()
    this.points = points
  }

  getPoint(t: number) {
    var points = this.points

    var d = (points.length - 1) * t

    var index1 = Math.floor(d)
    var index2 = index1 < points.length - 1 ? index1 + 1 : index1

    var pt1 = points[index1]
    var pt2 = points[index2]

    var weight = d - index1

    const returnPoint = new Vector3().copy(pt1).lerp(pt2, weight)

    return returnPoint
  }
}

interface CircleType {
  circleCenter: Vector3
  circleRadius: number
}

interface ConvexHullElement {
  x: number
  y: number
  circleIndexes: number[]
  u: number
  index?: number
  circleStartUValue?: number
  circleEndUValue?: number
  lengthOfSection?: number
}

class CircleAdditiveShape {
  circles: CircleType[]
  convexHull: ConvexHullElement[]

  constructor(baseCircleRadius: number, bubbleCircles: CircleType[]) {
    this.circles = [
      { circleCenter: new Vector3(0, 0, 0), circleRadius: baseCircleRadius },
      ...bubbleCircles,
    ]
    const intersectionPoints = this.getIntersectionPointsAllPairs(this.circles)

    this.convexHull = this.getConvexHull(intersectionPoints)
  }

  getUValueFromPoint(point: { x: number; y: number }, center = { x: 0, y: 0 }) {
    const centerVector = new Vector3(center.x, center.y, 0)
    if (!point) console.log(this.circles)
    const vector1 = new Vector3(point.x, point.y, 0).sub(centerVector)
    const unitVectorY = new Vector3(0, 1, 0).sub(centerVector)

    let u = vector1.angleTo(unitVectorY)

    const crossProduct = unitVectorY.clone().cross(vector1)
    if (crossProduct.z < 0) u = 2 * Math.PI - u

    u = u / (2 * Math.PI)

    return u
  }

  getConvexHull(intersectionPointsSorted: SortedArray<ConvexHullElement>) {
    const intersectionPoints = intersectionPointsSorted.array
    // const lowestPoint = this.getLowestPoint(intersectionPoints);
    const stack: {
      x: number
      y: number
      circleIndexes: number[]
      u: number
    }[] = []

    // stack.push(lowestPoint);

    for (let index = 0; index < intersectionPoints.length; index++) {
      // const index = lowestPoint.index + i > intersectionPoints.length - 1 ? lowestPoint.index + i - intersectionPoints.length : lowestPoint.index + i;
      const intersectionPoint = intersectionPoints[index]
      const intersectionPointVector = new Vector3(
        intersectionPoint.x,
        intersectionPoint.y,
        0
      )

      const containingCircles = this.circles.filter((circle, circleIndex) => {
        if (intersectionPoint.circleIndexes.includes(circleIndex)) return false
        else {
          const distanceVector = intersectionPointVector
            .clone()
            .sub(circle.circleCenter)
          const horizontalDistance = Math.hypot(
            distanceVector.x,
            distanceVector.y
          )

          if (horizontalDistance < circle.circleRadius) return true
          else return false
        }
      })

      if (containingCircles.length == 0) stack.push(intersectionPoint)
    }

    return stack
  }

  getLowestPoint(intersectionPoints: Vector3[] | ConvexHullElement[]) {
    let lowestPoint: any = { y: 9999999, x: 9999999 } //TODO maybe change this to something more elegant. It's set to an arbitrary high number that a realistic print volume can't reach.
    intersectionPoints.forEach((point, _) => {
      if (point.y < lowestPoint.y) {
        lowestPoint = { ...point }
      }
    })

    return lowestPoint
  }

  getIntersectionPointsAllPairs(circularSections: CircleType[]) {
    const calculateIntersectionPointsOfTwoCircles = (
      A: { x: number; y: number; r: number; index: number },
      B: { x: number; y: number; r: number; index: number }
    ) => {
      const d = Math.hypot(B.x - A.x, B.y - A.y)
      let P1: { x: number; y: number } | undefined = undefined
      let P2: { x: number; y: number } | undefined = undefined

      if (d <= A.r + B.r && d >= Math.abs(B.r - A.r)) {
        const ex = (B.x - A.x) / d
        const ey = (B.y - A.y) / d

        const x = (A.r * A.r - B.r * B.r + d * d) / (2 * d)
        const y = Math.sqrt(A.r * A.r - x * x)

        P1 = {
          x: A.x + x * ex - y * ey,
          y: A.y + x * ey + y * ex,
        }

        P2 = {
          x: A.x + x * ex + y * ey,
          y: A.y + x * ey - y * ex,
        }
      }

      if (P1 && P2) {
        return [
          {
            x: P1.x,
            y: P1.y,
            circleIndexes: [A.index, B.index],
            u: this.getUValueFromPoint(P1),
          },
          {
            x: P2.x,
            y: P2.y,
            circleIndexes: [B.index, A.index],
            u: this.getUValueFromPoint(P2),
          },
        ]
      } else {
        return [undefined, undefined]
      }
    }

    const intersectionPoints = new SortedArray<ConvexHullElement>('u', [])

    for (let i = 0; i < circularSections.length - 1; i++) {
      for (let j = i + 1; j < circularSections.length; j++) {
        const A = {
          x: circularSections[i].circleCenter.x,
          y: circularSections[i].circleCenter.y,
          index: i,
          r: circularSections[i].circleRadius,
        }
        const B = {
          x: circularSections[j].circleCenter.x,
          y: circularSections[j].circleCenter.y,
          index: j,
          r: circularSections[j].circleRadius,
        }

        const [point1, point2] = calculateIntersectionPointsOfTwoCircles(A, B)

        if (point1 && point2) {
          intersectionPoints.insert(point1)
          intersectionPoints.insert(point2)
        }
      }
    }

    return intersectionPoints
  }

  getPoint(u: number) {
    let concernedCircle = undefined
    let intersectionPoint1:
      | { x: number; y: number; circleIndexes: number[]; u: number }
      | undefined = undefined
    let intersectionPoint2:
      | { x: number; y: number; circleIndexes: number[]; u: number }
      | undefined = undefined

    const mainU_CyclicNormalizedValue = new CyclicNormalizedValue(u)

    for (let i = 0; i < this.convexHull.length; i++) {
      let nextIndex = undefined

      if (i + 1 > this.convexHull.length - 1) nextIndex = 0
      else nextIndex = i + 1

      if (
        mainU_CyclicNormalizedValue.isValueBetween(
          this.convexHull[i].u,
          this.convexHull[nextIndex].u
        )
      ) {
        intersectionPoint1 = this.convexHull[i]
        intersectionPoint2 = this.convexHull[nextIndex]

        const commonCircles = intersectionPoint1.circleIndexes.filter(
          (circleIndex) =>
            intersectionPoint2?.circleIndexes.includes(circleIndex)
        )

        if (commonCircles.length == 1) {
          concernedCircle = this.circles[commonCircles[0]]
        } else if (commonCircles.length > 1) {
          concernedCircle = this.circles[commonCircles[0]]
        } else {
          throw 'the intersection points do not lie on the circumference of the same circle'
        }

        break
      }
    }

    const concernedCircleStartingU = intersectionPoint1
      ? this.getUValueFromPoint(
          intersectionPoint1,
          concernedCircle?.circleCenter
        )
      : 0
    const concernedCircleEndingU = intersectionPoint2
      ? this.getUValueFromPoint(
          intersectionPoint2,
          concernedCircle?.circleCenter
        )
      : 1

    if (!concernedCircle) concernedCircle = this.circles[0]

    if (!intersectionPoint1)
      intersectionPoint1 = { x: 0, y: 0, circleIndexes: [], u: 0 }
    if (!intersectionPoint2)
      intersectionPoint2 = { x: 0, y: 0, circleIndexes: [], u: 1 }

    const normalizedU =
      mainU_CyclicNormalizedValue.getCyclicDistanceFrom(intersectionPoint1.u) /
      CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
        intersectionPoint1.u,
        intersectionPoint2.u
      )
    const translatedU =
      CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
        concernedCircleStartingU,
        concernedCircleEndingU
      ) *
        normalizedU +
      concernedCircleStartingU

    const x =
      concernedCircle.circleRadius *
      Math.cos(translatedU * 2 * Math.PI + Math.PI / 2)
    const y =
      concernedCircle.circleRadius *
      Math.sin(translatedU * 2 * Math.PI + Math.PI / 2)
    const pointVector = new Vector3(x, y, 0)

    const vector1 = new Vector3(
      concernedCircle.circleCenter.x,
      concernedCircle.circleCenter.y,
      0
    )
    const unitVectorY = new Vector3(0, -1, 0)

    let angle = vector1.angleTo(unitVectorY)

    const crossProduct = unitVectorY.clone().cross(vector1)
    if (crossProduct.z < 0) angle = 2 * Math.PI - angle

    if (
      Math.hypot(
        concernedCircle.circleCenter.x,
        concernedCircle.circleCenter.y
      ) > 0.00000001
    )
      pointVector.applyAxisAngle(new Vector3(0, 0, 1), angle)
    pointVector.add(
      new Vector3(
        concernedCircle.circleCenter.x,
        concernedCircle.circleCenter.y,
        0
      )
    )
    return pointVector
  }
}

class CylinderAdditiveShape extends CircleAdditiveShape {
  totalLength?: number

  constructor(baseCircleRadius: number, bubbleCircles: CircleType[]) {
    super(baseCircleRadius, bubbleCircles)

    if (this.convexHull.length > 0)
      this.convexHull =
        this.calculateCylinderHullPeripheryAndSetTotalLengthOfCurve([
          ...this.convexHull,
        ])
  }

  calculateCylinderHullPeripheryAndSetTotalLengthOfCurve(
    convexHull: typeof this.convexHull
  ) {
    convexHull.forEach((point, index) => (point.index = index))
    const lowestPointIndex = this.getLowestPoint(convexHull).index
    let currentPoint = convexHull[lowestPointIndex]
    const finalConvexHull = []

    this.totalLength = 0

    while (true) {
      const currentCircleIndex = currentPoint.circleIndexes[0]
      const previousCircleIndex = currentPoint.circleIndexes[1]

      const circleStartUValue = this.getUValueFromPoint(
        currentPoint,
        this.circles[currentCircleIndex].circleCenter
      )
      const circleEndUValue = this.getUValueFromPoint(
        currentPoint,
        this.circles[previousCircleIndex].circleCenter
      )

      currentPoint.circleStartUValue = circleStartUValue
      currentPoint.circleEndUValue = circleEndUValue

      const possibleNextPoints = convexHull.filter(
        (point) => point.circleIndexes[1] == currentCircleIndex
      )

      const minDistance =
        CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
          circleStartUValue,
          this.getUValueFromPoint(
            possibleNextPoints[0],
            this.circles[currentCircleIndex].circleCenter
          )
        )

      const nextPoint = possibleNextPoints.reduce(
        (closestPoint, point) => {
          const distance =
            CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
              circleStartUValue,
              this.getUValueFromPoint(
                point,
                this.circles[currentCircleIndex].circleCenter
              )
            )

          if (distance < closestPoint.distance) return { distance, point }
          else return closestPoint
        },
        { distance: minDistance, point: possibleNextPoints[0] }
      ).point

      const nextPointIndex = convexHull.findIndex(
        (point) => point.index == nextPoint.index
      )
      const lengthOfSection =
        CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
          circleStartUValue,
          this.getUValueFromPoint(
            convexHull[nextPointIndex],
            this.circles[currentCircleIndex].circleCenter
          )
        ) *
        2 *
        Math.PI *
        this.circles[currentCircleIndex].circleRadius

      currentPoint.lengthOfSection = lengthOfSection
      finalConvexHull.push(currentPoint)
      this.totalLength += lengthOfSection

      currentPoint = convexHull[nextPointIndex]

      if (nextPoint.index == lowestPointIndex) break
    }

    return finalConvexHull
  }

  getPoint(u: number) {
    if (this.convexHull.length == 0) {
      const concernedCircle = this.circles[0]
      const x =
        concernedCircle.circleRadius * Math.cos(u * 2 * Math.PI + Math.PI / 2)
      const y =
        concernedCircle.circleRadius * Math.sin(u * 2 * Math.PI + Math.PI / 2)
      return new Vector3(x, y, 0)
    } else {
      let concernedCircle = undefined
      let intersectionPoint1 = undefined
      let intersectionPoint2 = undefined
      const lengthOfCurveAtThisU = u * this.totalLength!
      let lengthAcc = 0

      for (let i = 0; i < this.convexHull.length; i++) {
        const thisPoint = this.convexHull[i]
        const nextPoint =
          i == this.convexHull.length - 1
            ? this.convexHull[0]
            : this.convexHull[i + 1]

        const sectionLength = thisPoint.lengthOfSection

        if (lengthOfCurveAtThisU <= lengthAcc + sectionLength!) {
          intersectionPoint1 = thisPoint
          intersectionPoint2 = nextPoint
          concernedCircle = this.circles[thisPoint.circleIndexes[0]]
          break
        }
        lengthAcc += sectionLength!
      }

      if (!concernedCircle)
        throw 'Check logic for getting point on a cylinder, it seems wrong'

      const concernedCircleStartingU = intersectionPoint1
        ? this.getUValueFromPoint(
            intersectionPoint1,
            concernedCircle.circleCenter
          )
        : 0
      const concernedCircleEndingU = intersectionPoint2
        ? this.getUValueFromPoint(
            intersectionPoint2,
            concernedCircle.circleCenter
          )
        : 1

      if (!concernedCircle) concernedCircle = this.circles[0]

      if (!intersectionPoint1) intersectionPoint1 = { u: 0 }
      if (!intersectionPoint2) intersectionPoint2 = { u: 1 }

      const normalizedU =
        (lengthOfCurveAtThisU - lengthAcc) / intersectionPoint1.lengthOfSection!
      const translatedU =
        CyclicNormalizedValue.getCyclicNormalizedDistanceBetween(
          concernedCircleStartingU,
          concernedCircleEndingU
        ) *
          normalizedU +
        concernedCircleStartingU

      const x =
        concernedCircle.circleRadius *
        Math.cos(translatedU * 2 * Math.PI + Math.PI / 2)
      const y =
        concernedCircle.circleRadius *
        Math.sin(translatedU * 2 * Math.PI + Math.PI / 2)
      const pointVector = new Vector3(x, y, 0)

      const vector1 = new Vector3(
        concernedCircle.circleCenter.x,
        concernedCircle.circleCenter.y,
        0
      )
      const unitVectorY = new Vector3(0, -1, 0)

      let angle = vector1.angleTo(unitVectorY)

      const crossProduct = unitVectorY.clone().cross(vector1)
      if (crossProduct.z < 0) angle = 2 * Math.PI - angle

      if (
        Math.hypot(
          concernedCircle.circleCenter.x,
          concernedCircle.circleCenter.y
        ) > 0.00000001
      )
        pointVector.applyAxisAngle(new Vector3(0, 0, 1), angle)
      pointVector.add(
        new Vector3(
          concernedCircle.circleCenter.x,
          concernedCircle.circleCenter.y,
          0
        )
      )
      return pointVector
    }
  }
}

export enum ShapeTypeFlags {
  showTiltInAdvancedEditor,
  showNSidesInAdvancedEditor,
  showNKnotsInAdvancedEditor,
  showRatioInAdvancedEditor,
  showSplineEditorInAdvancedEditor,
}

export class ShapeCurve {
  minPoints: number
  zPosition: number
  angle: number
  parametricEquation: (t: number) => Vector3
  parametricSlopeEquation: (t: number) => number
  zPositionAbsolute!: number
  type: string
  flags: ShapeTypeFlags[]

  constructor(
    minPoints: number,
    zPosition: number,
    angle: number,
    parametricEquation: (t: number) => Vector3,
    parametricSlopeEquation: (t: number) => number,
    type = 'shape',
    flags: ShapeTypeFlags[] = []
  ) {
    this.minPoints = minPoints
    this.zPosition = zPosition
    this.angle = angle
    this.type = type
    this.parametricEquation = parametricEquation
    this.parametricSlopeEquation = parametricSlopeEquation
    this.flags = flags
  }

  getPoints(numberOfPoints: number) {
    const vertices = []

    for (let i = 0; i < 1; i += 1 / numberOfPoints)
      vertices.push(this.getPoint(i))

    return vertices
  }

  getPolylineString(cardWidth: number) {
    let points = this.getPoints(80)

    if (points != undefined) {
      let polylineString = points.reduce(
        (prevValue, currentValue) =>
          prevValue +
          ((currentValue.x / 30) * cardWidth + cardWidth / 2) +
          ' ' +
          ((currentValue.y / 30) * cardWidth + cardWidth / 2) +
          ', ',
        ''
      )

      return polylineString.slice(0, -2)
    }
  }

  //abstract function that the child classes must implement

  static serialize(obj: ShapeCurve) {
    switch (obj.type) {
      case 'circle':
        return CircleShape.serialize(obj as CircleShape)
        break
      case 'polygon':
        return PolygonShape.serialize(obj as PolygonShape)
        break
      case 'oval':
        return OvalShape.serialize(obj as OvalShape)
        break
      case 'ineShape':
        return IneShape.serialize(obj as IneShape)
        break
      case 'custom':
        return CustomShape.serialize(obj as CustomShape)
        break
    }
  }
  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)
    switch (jsonObj[0]) {
      case 'circle':
        return CircleShape.deserialize(jsonString)
        break
      case 'polygon':
        return PolygonShape.deserialize(jsonString)
        break
      case 'oval':
        return OvalShape.deserialize(jsonString)
        break
      case 'ineShape':
        return IneShape.deserialize(jsonString)
        break
      case 'custom':
        return CustomShape.deserialize(jsonString)
        break
      default:
        throw 'Type of shape to deserialize not found'
    }
  }

  //Abstract methods that every child class must implement

  getPoint(t: number) {
    return this.parametricEquation(t).clone()
  }

  getSlope(t: number) {
    return this.parametricSlopeEquation(t)
  }
}

export class CircleShape extends ShapeCurve {
  radius: number
  constructor(radius: number, zPosition: number, angle: number) {
    function parametricEquation(t: number) {
      const x = radius * Math.cos(t * 2 * Math.PI + Math.PI / 2)
      const y = radius * Math.sin(t * 2 * Math.PI + Math.PI / 2)

      return new Vector3(x, y, 0)
    }

    function parametricSlopeEquation(t: number) {
      const dx = -1 * radius * Math.sin(t * 2 * Math.PI + Math.PI / 2)
      const dy = radius * Math.cos(t * 2 * Math.PI + Math.PI / 2)

      return dy / dx
    }

    super(
      80,
      zPosition,
      angle,
      parametricEquation,
      parametricSlopeEquation,
      'circle',
      [ShapeTypeFlags.showTiltInAdvancedEditor]
    )

    this.radius = radius
  }

  static serialize(obj: CircleShape) {
    return JSON.stringify([obj.type, obj.radius, obj.zPosition, obj.angle])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)

    return new CircleShape(jsonObj[1], jsonObj[2], jsonObj[3])
  }
}

class ArcShape extends ShapeCurve {
  radius: number
  startingAngle: number
  endingAngle: number

  constructor(
    startingAngle: number,
    endingAngle: number,
    center: Vector2 | Vector3,
    radius: number
  ) {
    function parametricEquation(t: number) {
      const x =
        center.x +
        radius * Math.cos(startingAngle + t * (endingAngle - startingAngle))
      const y =
        center.y +
        radius * Math.sin(startingAngle + t * (endingAngle - startingAngle))

      return new Vector3(x, y, 0)
    }

    function parametricSlopeEquation(t: number) {
      const dx =
        -1 *
        radius *
        Math.sin(startingAngle + t * (endingAngle - startingAngle))
      const dy =
        radius * Math.cos(startingAngle + t * (endingAngle - startingAngle))

      return dy / dx
    }

    super(80, 0, 0, parametricEquation, parametricSlopeEquation, 'arc')

    this.radius = radius
    this.startingAngle = startingAngle
    this.endingAngle = endingAngle
  }
}

class LineShape extends ShapeCurve {
  startingPoint: Vector3
  endingPoint: Vector3

  constructor(startingPoint: Vector3, endingPoint: Vector3) {
    function parametricEquation(t: number) {
      return endingPoint
        .clone()
        .sub(startingPoint)
        .multiplyScalar(t)
        .add(startingPoint)
    }

    function parametricSlopeEquation(t: number) {
      const point1 = startingPoint
      const point2 = endingPoint

      return (point1.y - point2.y) / (point1.x - point2.x)
    }

    super(80, 0, 0, parametricEquation, parametricSlopeEquation, 'line')
    this.startingPoint = startingPoint
    this.endingPoint = endingPoint
  }
}

export class PolygonShape extends ShapeCurve {
  numberOfSides: number
  cornerCenters: Vector3[]
  cornerRadius: number

  constructor(numberOfSides: number, zPosition: number, angle: number) {
    if (numberOfSides < 3 || numberOfSides > 8)
      throw 'Only polygons between 3 to 8 sides are supported'

    const radius = 10 / Math.cos((2 * Math.PI) / (numberOfSides * 2)) //This the radius of the circle that the regular polygon would be inscribed inside of.
    const theta = ((180 * (numberOfSides - 2)) / numberOfSides / 180) * Math.PI //The angle between any two sides of the regular polygon
    const sizeOfSide = radius * Math.cos(theta / 2) * 2 //Length of one side of the regular polygon
    const cornerRadius = sizeOfSide * 0.05 * numberOfSides //Radius of the rounded corner
    const cornerCenterInset = cornerRadius / Math.sin(theta / 2) //The distance between where the sharp vertex of the polygon should have been and where the center of the rounded corner exists
    const insetRadiusRatio = 1 - cornerCenterInset / radius
    const phi = Math.PI - theta //if you draw the corner into a full circle with the edges of the polygon as tangents.
    // This is the angle between the lines between where the tangents touch the circle andthe center of the circle.

    const singleArcLength = phi * cornerRadius
    const singleStraightEdgeLength =
      sizeOfSide - 2 * (cornerRadius / Math.tan(theta / 2))

    const truePerimeter =
      numberOfSides * (singleArcLength + singleStraightEdgeLength)
    const sectionEquations: any = []

    const cornerCenters = []

    const vertices = []
    const circle = new CircleShape(radius, 0, 0)

    for (let i = 0; i < numberOfSides; i++) {
      const t = (1 / numberOfSides) * i
      const point = circle.getPoint(t)
      vertices.push(point)
      cornerCenters.push(point.clone().multiplyScalar(insetRadiusRatio))
    }

    let tempSection: ShapeCurve = new ArcShape(
      Math.PI / 2,
      Math.PI / 2 + phi / 2,
      cornerCenters[0],
      cornerRadius
    )

    sectionEquations.push({
      equation: tempSection,
      length: singleArcLength / 2,
      startT: 0,
      endT: singleArcLength / (2 * truePerimeter),
    })

    for (let i = 0; i < numberOfSides; i++) {
      const index1 = i
      const index2 = i + 1 < numberOfSides ? i + 1 : i + 1 - numberOfSides
      const center1 = cornerCenters[index1]
      const center2 = cornerCenters[index2]

      const radiusVector = new Vector3(0, cornerRadius, 0)
      const lineStartingPoint = radiusVector
        .clone()
        .applyAxisAngle(new Vector3(0, 0, 1), index1 * phi + phi / 2)
        .add(center1)
      const lineEndingPoint = radiusVector
        .clone()
        .applyAxisAngle(new Vector3(0, 0, 1), index2 * phi - phi / 2)
        .add(center2)

      tempSection = new LineShape(lineStartingPoint, lineEndingPoint)
      sectionEquations.push({
        equation: tempSection,
        length: singleStraightEdgeLength,
        startT: sectionEquations[sectionEquations.length - 1].endT,
        endT:
          sectionEquations[sectionEquations.length - 1].endT +
          singleStraightEdgeLength / truePerimeter,
      })

      if (i < numberOfSides - 1) {
        tempSection = new ArcShape(
          index2 * phi - phi / 2 + Math.PI / 2,
          index2 * phi + phi / 2 + Math.PI / 2,
          cornerCenters[index2],
          cornerRadius
        )
        sectionEquations.push({
          equation: tempSection,
          length: singleArcLength,
          startT: sectionEquations[sectionEquations.length - 1].endT,
          endT:
            sectionEquations[sectionEquations.length - 1].endT +
            singleArcLength / truePerimeter,
        })
      }
    }
    tempSection = new ArcShape(
      Math.PI / 2 - phi / 2,
      Math.PI / 2,
      cornerCenters[0],
      cornerRadius
    )

    sectionEquations.push({
      equation: tempSection,
      length: singleArcLength / 2,
      startT: sectionEquations[sectionEquations.length - 1].endT,
      endT:
        sectionEquations[sectionEquations.length - 1].endT +
        singleArcLength / (2 * truePerimeter),
    })

    sectionEquations[sectionEquations.length - 1].endT = 1

    function parametricEquation(t: number) {
      const index = sectionEquations.findIndex(
        (section: any) => t <= section.endT
      )

      !sectionEquations[index] && console.log(sectionEquations, index, t)
      const metaT =
        (t - sectionEquations[index].startT) /
        (sectionEquations[index].endT - sectionEquations[index].startT)

      return sectionEquations[index].equation.getPoint(metaT, false)
    }

    function parametricSlopeEquation(t: number) {
      const index = sectionEquations.findIndex(
        (section: any) => t <= section.endT
      )

      !sectionEquations[index] && console.log(sectionEquations, index, t)
      const metaT =
        (t - sectionEquations[index].startT) /
        (sectionEquations[index].endT - sectionEquations[index].startT)

      return sectionEquations[index].equation.getSlope(metaT)
    }

    super(
      15 * numberOfSides,
      zPosition,
      angle,
      parametricEquation,
      parametricSlopeEquation,
      'polygon',
      [
        ShapeTypeFlags.showTiltInAdvancedEditor,
        ShapeTypeFlags.showNSidesInAdvancedEditor,
      ]
    )

    this.numberOfSides = numberOfSides
    this.cornerCenters = cornerCenters
    this.cornerRadius = cornerRadius
  }

  static serialize(obj: PolygonShape) {
    return JSON.stringify([
      obj.type,
      obj.numberOfSides,
      obj.zPosition,
      obj.angle,
    ])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)

    return new PolygonShape(jsonObj[1], jsonObj[2], jsonObj[3])
  }
}

export class OvalShape extends ShapeCurve {
  radius: number
  ratio: number

  constructor(ratio: number, radius: number, zPosition: number, angle: number) {
    function parametricEquation(t: number) {
      const x = 2 * ratio * radius * Math.cos(t * 2 * Math.PI + Math.PI / 2)
      const y =
        2 * (1 - ratio) * radius * Math.sin(t * 2 * Math.PI + Math.PI / 2)

      return new Vector3(x, y, 0)
    }

    function parametricSlopeEquation(t: number) {
      const dx =
        -1 * 2 * ratio * radius * Math.sin(t * 2 * Math.PI + Math.PI / 2)
      const dy =
        2 * (1 - ratio) * radius * Math.cos(t * 2 * Math.PI + Math.PI / 2)

      return dy / dx
    }

    super(
      80,
      zPosition,
      angle,
      parametricEquation,
      parametricSlopeEquation,
      'oval',
      [
        ShapeTypeFlags.showRatioInAdvancedEditor,
        ShapeTypeFlags.showTiltInAdvancedEditor,
      ]
    )

    this.radius = radius
    this.ratio = ratio
  }

  static serialize(obj: OvalShape) {
    return JSON.stringify([
      obj.type,
      Number(obj.ratio.toFixed(2)),
      obj.radius,
      obj.zPosition,
      obj.angle,
    ])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)
    return new OvalShape(jsonObj[1], jsonObj[2], jsonObj[3], jsonObj[4])
  }
}

export class CustomShape extends ShapeCurve {
  catmullRom: CatmullRomCurve3
  spline: CustomShapeSpline
  numberOfKnots: number

  constructor(spline: CustomShapeSpline, zPosition: number, angle: number) {
    const newCatmullRom = new CatmullRomCurve3(
      spline.curve.points.map((vertex) => vertex.clone().divideScalar(-4)),
      true
    )

    function parametricEquation(t: number) {
      return newCatmullRom.getPointAt(t)
    }

    function parametricSlopeEquation(t: number) {
      const tangentVector = newCatmullRom.getTangentAt(t)
      return tangentVector.y / tangentVector.x
    }

    super(
      10 * spline.knots.length,
      zPosition,
      angle,
      parametricEquation,
      parametricSlopeEquation,
      'custom',
      [
        ShapeTypeFlags.showTiltInAdvancedEditor,
        ShapeTypeFlags.showNKnotsInAdvancedEditor,
        ShapeTypeFlags.showSplineEditorInAdvancedEditor,
      ]
    )

    this.catmullRom = newCatmullRom
    this.spline = spline
    this.numberOfKnots = spline.curve.points.length
    this.angle = angle
  }

  static serialize(obj: CustomShape) {
    return JSON.stringify([
      obj.type,
      CustomShapeSpline.serialize(obj.spline),
      obj.zPosition,
      obj.angle,
    ])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)

    return new CustomShape(
      CustomShapeSpline.deserialize(jsonObj[1]),
      jsonObj[2],
      jsonObj[3]
    )
  }
}

export class IneShape extends ShapeCurve {
  spline: IneSpline
  constructor(spline: IneSpline, zPosition: number, angle: number) {
    function parametricEquation(t: number) {
      return spline.curve.curvePath
        .getPointAt(t)
        .multiplyScalar(2 * editorTypes.boucle.getSmallerXYDimension())
    }

    function parametricSlopeEquation(t: number) {
      const tangentVector = spline.curve.curvePath.getTangentAt(t)
      return tangentVector.y / tangentVector.x
    }

    super(
      80,
      zPosition,
      angle,
      parametricEquation,
      parametricSlopeEquation,
      'ineShape'
    )

    this.spline = spline
  }

  static serialize(obj: IneShape) {
    return JSON.stringify([
      obj.type,
      IneSpline.serialize(obj.spline),
      obj.zPosition,
      obj.angle,
    ])
  }

  static deserialize(jsonString: string) {
    const jsonObj = JSON.parse(jsonString)
    const debugTemp = IneSpline.deserialize(jsonObj[1])

    return new IneShape(
      IneSpline.deserialize(jsonObj[1]),
      jsonObj[2],
      jsonObj[3]
    )
  }
}

export class MeshMaker {
  additionalOptions: any
  circleAdditiveShapes: any
  spheres: Sphere[] | Disk[]
  disks: Disk[]
  hideModifiers: boolean
  surfaceModifier: any
  allShapes: ShapeCurve[]
  editorType: EditorType
  curve: CustomCatmullRom
  exportResolution: number
  angle: number
  socketSize: number
  modelType: ModelType
  materialColor: string
  splineLength: number
  socketPartLength: number
  leastz: number
  displayResolutionU: number
  displayResolutionV: number
  shouldTaperModifier: boolean
  socketShape?: ShapeCurve

  constructor(
    geometryState: GeometryStateType,
    lowWeight = false,
    hideModifiers = false
  ) {
    this.additionalOptions = geometryState.additionalOptions
    this.circleAdditiveShapes = {}
    this.spheres = geometryState.spheres
    this.disks = geometryState.disks
    this.hideModifiers = hideModifiers
    this.surfaceModifier = geometryState.surfaceModifier

    geometryState.profiles.intermediateShapes.forEach(
      (shape) =>
        (shape.angle =
          shape.zPosition * geometryState.profiles.endProfile.angle)
    )
    this.allShapes = [
      geometryState.profiles.startProfile,
      ...geometryState.profiles.intermediateShapes,
      geometryState.profiles.endProfile,
    ]
    this.editorType = geometryState.modelType.editorType
    this.curve = geometryState.spline.curve

    this.allShapes.forEach(
      (shape) =>
        (shape.zPositionAbsolute =
          shape.zPosition *
          (this.curve.getPhysicalPoint(1, geometryState.modelType.editorType)
            .y -
            this.curve.getPhysicalPoint(0, geometryState.modelType.editorType)
              .y))
    )

    this.exportResolution = 0.005

    this.angle = (90 * Math.PI) / 180
    this.socketSize = geometryState.modelType.socketSize
    this.modelType = geometryState.modelType
    this.materialColor = geometryState.color

    this.splineLength = this.curve.getPhysicalLength(
      geometryState.modelType.editorType
    )

    this.socketPartLength = this.getPointBaseOnly(0, -1, false).distanceTo(
      this.getPointBaseOnly(0, 0, false)
    )
    this.leastz = this.getPointBaseOnly(0, -1, false).z

    this.displayResolutionU = lowWeight ? 0.015 : 0.003
    this.displayResolutionV = lowWeight ? 0.015 : 0.005

    this.shouldTaperModifier = this.modelType.flags.includes(
      modelTypeFlags.shouldTaperModifier
    )
  }

  getCircularLoopGeometry(loop: Vector3[], radius: number, segments: number) {
    const curvePath = new Polyline3D([...loop, loop[0]])

    const extrusionCircle = new EllipseCurve(
      0,
      0,
      radius,
      radius,
      0,
      2 * Math.PI,
      false,
      0
    )

    const extrusionCircleShape = new Shape(extrusionCircle.getPoints(segments))

    const extrudeSettings = {
      steps: curvePath.points.length - 1,
      extrudePath: curvePath,
    }
    const geometry = new ExtrudeGeometry(extrusionCircleShape, extrudeSettings)

    return geometry
  }

  getConnectingLoopsAndSupportingAttributes(
    resolutionU: number,
    resolutionV: number
  ): [connectingLoops: Vector3[][], additionalAttributesLoops: Vector2[][]] {
    let connectingLoops: Vector3[][] = []
    let normalsCorrespondingToEachPointOnTheLoop: number[][] = [] //this is used later to find out how to apply the modifier.
    let additionalAttributesLoops: Vector2[][] = [] //used to store the u and v value of a point so it can be used in the shader.

    for (let v = 0; v <= 1; v += resolutionV) {
      connectingLoops.push([])
      additionalAttributesLoops.push([])
      for (let u = 0; u < 1; u += resolutionU) {
        connectingLoops[connectingLoops.length - 1].push(this.getPoint(u, v))
        additionalAttributesLoops[additionalAttributesLoops.length - 1].push(
          new Vector2(u, v)
        )
      }
    }

    for (let loopIndex = 0; loopIndex < connectingLoops.length; loopIndex++) {
      {
        const loop = connectingLoops[loopIndex]

        normalsCorrespondingToEachPointOnTheLoop.push([])
        for (let i = 0; i < loop.length; i++) {
          const prevPoint = loop[i == 0 ? loop.length - 1 : i - 1]
          const nextPoint = loop[i == loop.length - 1 ? 0 : i + 1]

          const slope =
            (nextPoint.y - prevPoint.y) / (nextPoint.x - prevPoint.x)
          const normal = -1 / slope
          normalsCorrespondingToEachPointOnTheLoop[loopIndex].push(normal)
        }
      }
    }

    if (!this.hideModifiers)
      connectingLoops.forEach((loop, index) =>
        this.applySurfaceModifierToLoop(
          loop,
          index,
          normalsCorrespondingToEachPointOnTheLoop[index],
          additionalAttributesLoops[index]
        )
      )

    return [connectingLoops, additionalAttributesLoops]
  }

  getPlanarGeometry(resolutionU: number, resolutionV: number) {
    const [connectingLoops, additionalAttributesLoops] =
      this.getConnectingLoopsAndSupportingAttributes(resolutionU, resolutionV)

    let vertices: number[] = []
    for (
      let loopIndex = 0;
      loopIndex < connectingLoops.length - 1;
      loopIndex++
    ) {
      let loop1 = connectingLoops[loopIndex]
      let loop2 = connectingLoops[loopIndex + 1]

      for (let i = 0; i < loop1.length - 1; i++) {
        vertices.push(loop1[i + 1].x, loop1[i + 1].y, loop1[i + 1].z)
        vertices.push(loop2[i].x, loop2[i].y, loop2[i].z)
        vertices.push(loop1[i].x, loop1[i].y, loop1[i].z)

        vertices.push(loop2[i + 1].x, loop2[i + 1].y, loop2[i + 1].z)
        vertices.push(loop2[i].x, loop2[i].y, loop2[i].z)
        vertices.push(loop1[i + 1].x, loop1[i + 1].y, loop1[i + 1].z)
      }

      vertices.push(loop1[0].x, loop1[0].y, loop1[0].z)
      vertices.push(
        loop2[loop2.length - 1].x,
        loop2[loop1.length - 1].y,
        loop2[loop1.length - 1].z
      )
      vertices.push(
        loop1[loop1.length - 1].x,
        loop1[loop1.length - 1].y,
        loop1[loop1.length - 1].z
      )

      vertices.push(loop2[0].x, loop2[0].y, loop2[0].z)
      vertices.push(
        loop2[loop2.length - 1].x,
        loop2[loop1.length - 1].y,
        loop2[loop1.length - 1].z
      )
      vertices.push(loop1[0].x, loop1[0].y, loop1[0].z)
    }

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    return geometry
  }

  getExtrudedGeometry(
    resolutionU: number,
    resolutionV: number,
    segments: number
  ) {
    const [connectingLoops, additionalAttributesLoops] =
      this.getConnectingLoopsAndSupportingAttributes(resolutionU, resolutionV)

    const extrudedGeometries = []
    for (let loopIndex = 0; loopIndex < connectingLoops.length; loopIndex++) {
      const nextIndex =
        loopIndex == connectingLoops.length - 1 ? loopIndex - 1 : loopIndex + 1

      const zeroPointCurrentLayer = this.getPointBaseOnly(
        0,
        additionalAttributesLoops[loopIndex][0].y,
        this.modelType.id != modelTypes.bubble.id
      )
      const zeroPointNextLayer = this.getPointBaseOnly(
        0,
        additionalAttributesLoops[nextIndex][0].y,
        this.modelType.id != modelTypes.bubble.id
      )

      const layerLineThickness =
        zeroPointCurrentLayer.distanceTo(zeroPointNextLayer) / 2

      const loopedCylinder = this.getCircularLoopGeometry(
        connectingLoops[loopIndex],
        layerLineThickness,
        segments
      )
      extrudedGeometries.push(loopedCylinder)
    }

    const extrudedGeometry =
      BufferGeometryUtils.mergeBufferGeometries(extrudedGeometries)

    return extrudedGeometry
  }

  applySurfaceModifierToLoop(
    loop: Vector3[],
    zIndex: number,
    loopNormals: number[],
    additionalAttributesLoop: Vector2[]
  ) {
    if (
      this.surfaceModifier.name == constants.surfaceModifierNames.sinusoidAlt ||
      this.surfaceModifier.name == constants.surfaceModifierNames.sinusoid
    ) {
      loop.forEach((position, index) => {
        const [u, v] = [
          additionalAttributesLoop[index].x,
          additionalAttributesLoop[index].y,
        ]

        const socketFraction = this.shouldTaperModifier
          ? this.socketPartLength / (this.socketPartLength + this.splineLength)
          : 0

        const surfaceModifier = this.surfaceModifier

        const amplitude = surfaceModifier.amplitude
        const frequency = surfaceModifier.frequency

        const normalUnitVectorSlope = loopNormals[index]
        let normalVector = new Vector3(
          1.0 / Math.sqrt(Math.pow(normalUnitVectorSlope, 2.0) + 1.0),
          normalUnitVectorSlope /
            Math.sqrt(Math.pow(normalUnitVectorSlope, 2.0) + 1.0),
          0
        )

        if (normalVector.dot(position) > 0.0)
          normalVector = normalVector.multiplyScalar(-1)

        const normalizedV = this.shouldTaperModifier
          ? (v - socketFraction) / (1 - socketFraction)
          : 1

        if (v < socketFraction) void 0
        else if (
          this.surfaceModifier.name ==
            constants.surfaceModifierNames.sinusoid ||
          zIndex % 2 == 0
        ) {
          position.add(
            normalVector.multiplyScalar(
              normalizedV * amplitude * Math.sin(frequency * u * Math.PI * 2.0)
            )
          )
        } else {
          position.add(
            normalVector.multiplyScalar(
              normalizedV *
                amplitude *
                Math.sin(frequency * u * Math.PI * 2.0 + Math.PI)
            )
          )
        }
      })
    }
  }

  /*
   * Gets the actual mesh object that is displayed in the three js Canvas
   */
  getMesh(
    getPlanar = true,
    getWireframe = false,
    displayResolutionU = this.displayResolutionU,
    displayResolutionV = this.displayResolutionV,
    materialColor = this.materialColor,
    segments = 12
  ): [
    objectMesh: Mesh<BufferGeometry, MeshStandardMaterial>,
    helper: { boxHelper: BoxHelper; minVector: Vector3; maxVector: Vector3 }
  ] {
    const geometry = getPlanar
      ? this.getPlanarGeometry(displayResolutionU, displayResolutionV)
      : this.getExtrudedGeometry(
          displayResolutionU,
          displayResolutionV,
          segments
        )

    let newMaterial = new MeshStandardMaterial({
      color: parseInt(materialColor, 16),
      metalness: 0.2,
      roughness: 0.5,
      wireframe: getWireframe,
    })

    newMaterial.side = DoubleSide

    const objectMesh = new Mesh(geometry, newMaterial)
    objectMesh.castShadow = true
    objectMesh.receiveShadow = true
    //This transformation matrix just centers the model in the viewer.
    objectMesh.geometry.scale(0.1, 0.1, 0.1)
    //Three JS has a different orientation for axis than what most people would intuitively expect
    // The rotation below just rotates the object so it appears to be in the correct orientation

    objectMesh.geometry.rotateX(
      this.modelType.id == modelTypes.boucle.id ? Math.PI : Math.PI / 2
    )

    objectMesh.castShadow = true

    const bBox = new Box3()
    bBox.setFromObject(objectMesh)
    const maxVector = bBox.max
    const minVector = bBox.min
    const boxHelper = new BoxHelper(objectMesh, 'silver')

    return [objectMesh, { boxHelper, maxVector, minVector }]
  }

  getMeshAR(previousScene: Scene) {
    let scene = previousScene.clone()

    const newSceneChildren = scene.children.filter((object) => {
      return object.name == 'lampModel'
    })

    const lampMesh = this.getMesh(
      false,
      false,
      0.006,
      0.011,
      this.materialColor,
      3
    )[0]
    lampMesh.geometry.scale(0.01, 0.01, 0.01)

    newSceneChildren.push(lampMesh)

    scene.children = newSceneChildren

    scene.scale.set(0.01, 0.01, 0.01)
    scene.updateMatrixWorld(true)
    //Three JS has a different orientation for axis than what most people would intuitively expect
    // The rotation below just rotates the object so it appears to be in the correct orientation
    return scene
  }

  static getMeshARWaterDrop(previousScene: Scene) {
    let scene = previousScene.clone()

    const outsideObject = scene.children.filter((object) => {
      return object.name === 'objectOutside'
    })[0]

    const insideObject = scene.children.filter((object) => {
      return object.name === 'objectInside'
    })[0]

    scene.remove(outsideObject)
    scene.remove(insideObject)

    const objectMesh = outsideObject.clone() as Mesh
    const insideMesh = objectMesh.clone()

    objectMesh.material = (objectMesh.material as MeshStandardMaterial).clone()
    ;(objectMesh.material as MeshStandardMaterial).color = new Color(0xffffff)
    ;(insideMesh.material as MeshStandardMaterial).color = new Color(0xff0000)

    objectMesh.geometry = objectMesh.geometry.clone()

    const vertices = Array.from(
      objectMesh.geometry.getAttribute('position').array
    )

    let temp: number[] = []
    for (let i = 0; i < vertices.length; i += 9) {
      temp = [vertices[i], vertices[i + 1], vertices[i + 2]]
      vertices[i] = vertices[i + 6]
      vertices[i + 1] = vertices[i + 7]
      vertices[i + 2] = vertices[i + 8]

      vertices[i + 6] = temp[0]
      vertices[i + 7] = temp[1]
      vertices[i + 8] = temp[2]
    }

    const geometry = new BufferGeometry()

    geometry.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    geometry.computeVertexNormals()

    objectMesh.geometry = geometry
    scene.add(objectMesh)
    scene.add(insideMesh)

    scene.scale.set(0.001, 0.001, 0.001)
    scene.updateMatrixWorld(true)

    return scene
  }

  getPointBubble(u: number, v: number) {
    let circleAdditiveShape = undefined

    if (this.circleAdditiveShapes[v])
      //checks if we've already calculated the combined shape for this layer
      circleAdditiveShape = this.circleAdditiveShapes[v]
    else {
      const radiusOfMainCylinder =
        this.curve.getPhysicalPoint(0, editorTypes.bubble).x / 2
      const circularSections: CircleType[] = this.spheres
        .map((sphere) => sphere.getCirclularSection(this.curve, v))
        .filter((element) => element)
        .map((sphere) => sphere!)

      const taperHeightRatio = 30 / this.splineLength
      const smallerRadiusRatioTop = this.additionalOptions.topLipWidth
        ? (radiusOfMainCylinder - this.additionalOptions.topLipWidth) /
          radiusOfMainCylinder
        : 1
      const smallerRadiusRatioBottom = this.additionalOptions.bottomLipWidth
        ? (radiusOfMainCylinder - this.additionalOptions.bottomLipWidth) /
          radiusOfMainCylinder
        : 1

      if (v > 1 - taperHeightRatio)
        circleAdditiveShape = new CircleAdditiveShape(
          radiusOfMainCylinder *
            (Math.sin((((1 - v) / taperHeightRatio) * Math.PI) / 2) *
              (1 - smallerRadiusRatioBottom) +
              smallerRadiusRatioBottom),
          circularSections
        )
      else if (v > taperHeightRatio)
        circleAdditiveShape = new CircleAdditiveShape(
          radiusOfMainCylinder,
          circularSections
        )
      else
        circleAdditiveShape = new CircleAdditiveShape(
          radiusOfMainCylinder *
            (Math.sin(((v / taperHeightRatio) * Math.PI) / 2) *
              (1 - smallerRadiusRatioTop) +
              smallerRadiusRatioTop),
          circularSections
        )

      this.circleAdditiveShapes = {}
      this.circleAdditiveShapes[v] = circleAdditiveShape
    }

    const auxillaryPoint = circleAdditiveShape.getPoint(u)
    const basePoint = this.getPointBaseOnly(u, v, false)

    auxillaryPoint.z = basePoint.z
    return auxillaryPoint
  }

  getPointCandle(u: number, v: number) {
    let circleAdditiveShape = undefined

    if (this.circleAdditiveShapes.shape)
      //checks if we've already calculated the combined shape for this layer
      circleAdditiveShape = this.circleAdditiveShapes.shape
    else {
      const radiusOfMainCylinder =
        this.curve.getPhysicalPoint(0, editorTypes.bubble).x / 2
      const circularSections = this.disks
        .map((disk) => disk.getCirclularSection())
        .filter((element) => element)
      circleAdditiveShape = new CylinderAdditiveShape(
        radiusOfMainCylinder,
        circularSections
      )
      this.circleAdditiveShapes.shape = circleAdditiveShape
    }

    const auxillaryPoint = circleAdditiveShape.getPoint(u)
    const basePoint = this.getPointBaseOnly(u, v, false)

    auxillaryPoint.z = basePoint.z
    return auxillaryPoint
  }

  getPoint(u: number, v: number, translateToZeroOne = true) {
    if (this.modelType && this.modelType.id == modelTypes.bubble.id) {
      return this.getPointBubble(u, v)
    } else if (this.modelType && this.modelType.id == modelTypes.candle.id) {
      return this.getPointCandle(u, v)
    } else return this.getPointBaseOnly(u, v, translateToZeroOne)
  }

  getPointBaseOnly(u: number, v: number, translateToZeroOne = true): Vector3 {
    if (u > 1 || v > 1 || u < 0 || v < -1)
      throw (
        'Please enter (u,v) values for parametric model between (0,0) - (1,1)\nValue entered: (' +
        u +
        ', ' +
        v +
        ')'
      )

    //The function can be called in normal state or translateToZeroOne state.
    //In normal state v parameter has a range: [-1, 0] -> socket and [0,1] -> actual model
    //In translateToZeroOne the range is [0,1] this includes both socket and model
    //This also takes care of not creating closed bottoms for the mentioned models.
    //TODO this whole code has grown a lil too complicated and needs to be reformed.
    if (
      translateToZeroOne &&
      this.modelType &&
      this.modelType.id != modelTypes.sketch.id &&
      this.modelType.id != modelTypes.boucle.id &&
      this.modelType.id !== modelTypes.waterDrop.id &&
      this.modelType.id != modelTypes.sketch.id
    ) {
      const tLength = v * (this.splineLength + this.socketPartLength)

      if (tLength < this.socketPartLength) {
        const returnPoint = this.getPointBaseOnly(
          u,
          -1 + tLength / this.socketPartLength,
          false
        )
        returnPoint.z -= this.leastz
        return returnPoint
      } else {
        const returnPoint = this.getPointBaseOnly(
          u,
          Math.min((tLength - this.socketPartLength) / this.splineLength, 1),
          false
        )
        returnPoint.z -= this.leastz
        return returnPoint
      }
    }

    if (v >= 0 && v <= 1) {
      const positioning = Math.max(
        1,
        this.allShapes.findIndex((shape) => shape.zPosition >= v)
      )

      const shape1 = this.allShapes[positioning - 1]
      const shape2 = this.allShapes[positioning]

      const point1 = shape1.getPoint(u)
      point1.z = shape1.zPositionAbsolute

      const point2 = shape2.getPoint(u)
      point2.z = shape2.zPositionAbsolute

      const lineCurve = new LineCurve3(point1, point2)

      const surfacePoint = lineCurve.getPointAt(
        (v - shape1.zPosition) / (shape2.zPosition - shape1.zPosition)
      )

      const splinePoint = this.curve.getPhysicalPoint(v, this.editorType)
      surfacePoint.z =
        splinePoint.y - this.curve.getPhysicalPoint(0, this.editorType).y

      const scale = splinePoint.x / 20

      const transformationMatrix = new Matrix4()

      const angle =
        shape1.angle +
        ((shape2.angle - shape1.angle) * (v - shape1.zPosition)) /
          (shape2.zPosition - shape1.zPosition)

      //prettier-ignore
      transformationMatrix.set(
        scale * Math.cos(angle), -1 * scale * Math.sin(angle), 0, 0,
        scale * Math.sin(angle),      scale * Math.cos(angle), 0, 0,
                              0,                            0, 1, 0,
                              0,                            0, 0, 1
      )

      surfacePoint.applyMatrix4(transformationMatrix)

      return surfacePoint
    } else {
      v += 1

      let shape1 = new CircleShape(
        this.socketSize / 2,
        0,
        this.allShapes[0].angle
      )
      shape1.zPositionAbsolute = 0

      if (this.modelType.id == modelTypes.leroyMerlin.id)
        shape1.zPositionAbsolute = -63.4
      else if (this.modelType.id == modelTypes.pablo.id) {
        shape1 = new CircleShape(0, 0, this.allShapes[0].angle)
        shape1.zPositionAbsolute = 0
      }

      this.socketShape = shape1
      const shape2 = this.allShapes[0]

      const point1 = shape1.getPoint(u)
      point1.z = shape1.zPositionAbsolute

      const point2 = this.getPointBaseOnly(u, 0, false)
      point2.z = shape2.zPositionAbsolute

      const lineCurve = new LineCurve3(point1, point2)

      const surfacePoint = lineCurve.getPointAt(v)

      //const splinePoint = this.curve.getPhysicalPoint(0);
      const scale = 1

      const transformationMatrix = new Matrix4()

      const angle = this.allShapes[0].angle

      //prettier-ignore
      transformationMatrix.set(
        scale * Math.cos(angle), -1 * scale * Math.sin(angle), 0, 0,
        scale * Math.sin(angle),      scale * Math.cos(angle), 0, 0,
                              0,                            0, 1, 0,
                              0,                            0, 0, 1
      )

      surfacePoint.applyMatrix4(transformationMatrix) //.applyAxisAngle(new Vector3(0, 0, 1), angle)

      return surfacePoint
    }
  }

  getGcode() {
    //All the GCode stuff goes here

    let gcodeString = ''
    for (let v = 0; v <= 1; v += 0.001) {
      for (let u = 0; u < 1; u += this.displayResolutionU) {
        gcodeString +=
          'G0 X' +
          this.getPointBaseOnly(u, v).x +
          ' Y' +
          this.getPointBaseOnly(u, v).y +
          ' Z' +
          this.getPointBaseOnly(u, v).z +
          '\n'
      }
    }

    return gcodeString
  }

  translatePoints(points: Vector3[], x: number, y: number, z: number) {
    return points.map(
      (point) => new Vector3(point.x - y, point.y + z, point.z - x)
    )
  }

  scalePoints(points: Vector3[], scale: number) {
    return points.map(
      (point) => new Vector3(point.x * scale, point.y, point.z * scale)
    )
  }

  rotatePoints(points: Vector3[], axis: Vector3, angle: number) {
    return points.map((point) => point.applyAxisAngle(axis, angle))
  }

  //In three js the axes are pointed differently. Y axis points where we generally expect z axis to be, this function fixes that.
  getAdjustedPoint(a: Vector3) {
    //console.log(a["z"])
    if (a['z'] == undefined) return new Vector3(-1 * a['y'], 0, -1 * a['x'])
    else return new Vector3(-1 * a['y'], a['z'], -1 * a['x'])
  }

  getDeAdjustedPoint(a: Vector3) {
    return new Vector3(-1 * a.z, -1 * a.x, a.y)
  }

  static getPrice(minVector: Vector3, maxVector: Vector3) {
    const sizeOfDiagonal = minVector.distanceTo(maxVector)

    const fullPrice = Math.ceil(sizeOfDiagonal * 3) - 0.01
    const discountPercentage = discountPercentageConfig
    const discountedPrice =
      Math.ceil(((100 - discountPercentage) / 100) * fullPrice) - 0.01

    return { fullPrice, discountedPrice }
  }
}

export function meshmaker_from_json(data: any) {
  let state = GeometryState.convertJSONToGeometryState2(data)
  return new MeshMaker(state)
}
